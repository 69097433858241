import React from "react"
import cx from "classnames"
import styles from "./styles.module.css"
import Turtle from "./Turtle"

const Turtles = ({ number, className }) => {
  const t = []
  const u = []
  for (let i = 0; i < number; i++) {
    t.push("")
  }
  for (let j = 0; j < 4 - number; j++) {
    u.push("")
  }
  return (
    <span className={cx(styles.Turtles, className)}>
      {t.map((_, index) => (
        <Turtle />
      ))}
      {u.map((_, index) => (
        <span className={styles.Transparent}>
          <Turtle />
        </span>
      ))}
    </span>
  )
}

export default Turtles
