import React from "react"

const Turtle = () => (
  <svg
    id="Groupe_6160"
    data-name="Groupe 6160"
    xmlns="http://www.w3.org/2000/svg"
    width="22.852"
    height="22.937"
    viewBox="0 0 22.852 22.937"
  >
    <g id="Groupe_6159" data-name="Groupe 6159">
      <g id="Groupe_6158" data-name="Groupe 6158">
        <g
          id="Groupe_6157"
          data-name="Groupe 6157"
          transform="translate(3.766 3.673)"
        >
          <path
            id="Tracé_3495"
            data-name="Tracé 3495"
            d="M932.871,1495.123l-.128,4.178,2.454,1.624,3.8-1.75.144.312-3.735,1.721-.193,3.156,3.158,2.024q.072-.122.141-.25a7.1,7.1,0,0,0-5.8-11.022Z"
            transform="translate(-925.092 -1495.117)"
          />
          <path
            id="Tracé_3496"
            data-name="Tracé 3496"
            d="M926.042,1508.877l-2.723,1.357-.186,3.036,2.537,1.679,2.723-1.357.186-3.036Z"
            transform="translate(-918.615 -1504.43)"
          />
          <path
            id="Tracé_3497"
            data-name="Tracé 3497"
            d="M923.233,1525.734l-.084.131-3.219-2.064-2.731,1.361-.126,4.127-.344-.01.129-4.213-2.423-1.6-3.828,1.764-.119-.258a15.278,15.278,0,0,0,1.94,4.172S920.068,1530.8,923.233,1525.734Z"
            transform="translate(-910.057 -1514.301)"
          />
          <path
            id="Tracé_3498"
            data-name="Tracé 3498"
            d="M913.325,1503.994l.189-3.091-3.143-2.014.186-.289,3.144,2.016,2.761-1.376.126-4.122a7.092,7.092,0,0,0-5.863,3.181,8.015,8.015,0,0,0-1.156,7.428Z"
            transform="translate(-909.153 -1495.116)"
          />
        </g>
        <path
          id="Tracé_3499"
          data-name="Tracé 3499"
          d="M940.709,1487.521a7.106,7.106,0,0,1,2.668,1.722,2.339,2.339,0,0,0,1.56-1.016c.716-1.081,1.163-3.558.081-4.273s-3.282.6-4,1.681A2.34,2.34,0,0,0,940.709,1487.521Z"
          transform="translate(-926.693 -1483.75)"
        />
        <path
          id="Tracé_3500"
          data-name="Tracé 3500"
          d="M959.312,1515.892a5.035,5.035,0,0,0-4.21-3.288,7.112,7.112,0,0,1,.116,3,7.611,7.611,0,0,1,3.513.91C959.047,1516.661,959.443,1516.239,959.312,1515.892Z"
          transform="translate(-936.486 -1503.279)"
        />
        <path
          id="Tracé_3501"
          data-name="Tracé 3501"
          d="M912.227,1486.393a5.035,5.035,0,0,1,4.672,2.591,7.123,7.123,0,0,0-2.8,1.064,7.612,7.612,0,0,0-2.211-2.879C911.623,1486.937,911.857,1486.408,912.227,1486.393Z"
          transform="translate(-907.16 -1485.536)"
        />
        <path
          id="Tracé_3502"
          data-name="Tracé 3502"
          d="M897.506,1522.651a5.035,5.035,0,0,1,3.529-4.01,7.121,7.121,0,0,0,.44,2.968,7.61,7.61,0,0,0-3.284,1.544C897.908,1523.358,897.441,1523.016,897.506,1522.651Z"
          transform="translate(-897.5 -1507.365)"
        />
        <path
          id="Tracé_3503"
          data-name="Tracé 3503"
          d="M931.425,1544.433a5.036,5.036,0,0,0,2.313-4.815,7.122,7.122,0,0,1-2.9.754A7.615,7.615,0,0,1,930.7,1544C930.617,1544.339,931.114,1544.636,931.425,1544.433Z"
          transform="translate(-919.962 -1521.563)"
        />
      </g>
    </g>
  </svg>
)

export default Turtle
