import React from "react"

import Layout, { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import styles from "./404.styles.module.css"
import Services from "../components/shared/Services"

const ThankYouPage = () => (
  <Layout>
    <Inner>
      <section className={styles.Intro}>
        <Typography.h1>Merci !</Typography.h1>
        <Typography.p primary>Votre message a bien été envoyé !</Typography.p>
      </section>
    </Inner>

    <section className={styles.Services} id="services">
      <Inner>
        <Typography.h1 className={styles.ServicesTitle}>
          En attendant notre réponse, jettez un oeil à nos cours :
        </Typography.h1>
        <Services />
        <Button href="/reservation">Pour réserver, c'est ici</Button>
      </Inner>
    </section>
  </Layout>
)

export default ThankYouPage
