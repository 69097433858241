import React from "react"
import styles from "./styles.module.css"
import ServiceCard from "src/components/shared/ServiceCard"
import { graphql, StaticQuery } from "gatsby"

const Services = ({ services }) => {
  console.log(services)
  const servicesDict = Object.assign(
    {},
    ...services.map((service) => ({ [service.link.split("/")[2]]: service }))
  )

  return (
    <div>
      <div className={styles.Services}>
        <div className={styles.Col}>
          <ServiceCard {...servicesDict["bike-turtle"]} />
          <div className={styles.SubCol}>
            <ServiceCard {...servicesDict["train-turtle"]} />
            <ServiceCard {...servicesDict["free-turtle"]} />
          </div>
        </div>
        <div className={styles.Col}>
          <div className={styles.SubCol}>
            <ServiceCard {...servicesDict["cool-turtle"]} />
            <ServiceCard {...servicesDict["mix-turtle"]} />
          </div>
          <ServiceCard {...servicesDict["tonic-turtle"]} />
        </div>
      </div>
      <ServiceCard alternative {...servicesDict["mini-turtle"]} />
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query ServicesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "prestation" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                citation
                landscape
                name
                description
                duration
                difficulty
                cardio
                strengthening
                color
                icon
                portrait
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Services
        services={data.allMarkdownRemark.edges.map((edge) => ({
          ...edge.node.frontmatter,
          link: edge.node.fields.slug,
        }))}
      />
    )}
  />
)
