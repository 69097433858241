import PropTypes from "prop-types"
import React from "react"
import styles from "./styles.module.css"
import cx from "classnames"
import { Link } from "gatsby"
import Turtles from "src/components/shared/Turtles"

export const ServiceCardContainer = (props) => (
  <div {...props} className={styles.ServiceCardsContainer} />
)

const ServiceCard = ({
  name,
  landscape,
  portrait,
  description,
  duration,
  difficulty,
  cardio,
  strengthening,
  link,
  color,
  icon,
  alternative,
}) => (
  <Link
    className={cx(styles.Card, {
      [styles.Landscape]: landscape,
      [styles.Portrait]: portrait,
      [styles.Alternative]: alternative,
    })}
    style={{
      backgroundColor: color,
    }}
    to={link}
  >
    <h2 className={styles.Title}>{name}</h2>
    <div className={styles.Info}>
      <p className={styles.Description}>{description}</p>
      {duration ? <h4 className={styles.Subtitle}>{duration}</h4> : null}
      {difficulty ? <hr className={styles.Line} /> : null}
      {difficulty ? (
        <ul className={styles.List}>
          <li>
            <span>Difficulté</span> <Turtles number={difficulty} />
          </li>
          <li>
            <span>Cardio</span> <Turtles number={cardio} />
          </li>
          <li>
            <span>Renforcement</span> <Turtles number={strengthening} />
          </li>
        </ul>
      ) : null}
    </div>
    <div
      className={styles.Image}
      style={{
        backgroundImage: `url(${icon})`,
      }}
    ></div>
  </Link>
)

ServiceCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  landscape: PropTypes.bool,
  portrait: PropTypes.bool,
  difficulty: PropTypes.number,
  cardio: PropTypes.number,
  strengthening: PropTypes.number,
}

export default ServiceCard
